<template>
  <div v-loading="loading" class="container">
    <div class="operate">
      <div class="title-content">
        <div class="title">{{ $route.query.name }}-{{ subjectName }}成绩榜</div>
        <a-tooltip
          v-if="
            scoreType === 1 &&
            $route.query.ruleId > 0 &&
            ![1, 2, 3, 4, 8].includes(subjectId)
          "
          placement="top"
        >
          <template slot="title">
            <span>采用{{ dataType === 2 ? "赋分" : "原始分" }}数据源统计</span>
          </template>
          <a-radio-group
            v-model="dataType"
            button-style="solid"
            size="small"
            @change="dataTypeChange"
          >
            <a-radio-button :value="1"> 原始分 </a-radio-button>
            <a-radio-button :value="2"> 赋分 </a-radio-button>
          </a-radio-group>
        </a-tooltip>
      </div>
      <div class="btns">
        <a-space>
          <a-button
            v-if="type == '0'"
            type="primary"
            ghost
            :loading="btnLoading"
            @click="downloadFile"
            >下载{{ subjectName }}成绩榜</a-button
          >
          <a-button
            v-if="type === '1'"
            type="primary"
            ghost
            :loading="btnLoading"
            @click="downloadFile"
            >下载{{ subjectName }}详细成绩榜</a-button
          >
        </a-space>
      </div>
    </div>
    <div class="filter">
      <div class="filter-item">
        <a-select
          v-model="schoolId"
          style="width: 220px"
          @change="handleChange"
        >
          <a-select-option
            v-for="(item, index) in schoolList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <subjectList
        ref="subject"
        v-model="subjectId"
        :has-total="true"
        @change="subjectChange"
      />
    </div>
    <a-tabs v-model="type" type="card" @change="tabsChange">
      <a-tab-pane key="0" tab="成绩榜"> </a-tab-pane>
      <a-tab-pane key="1" tab="详细成绩榜"> </a-tab-pane>
    </a-tabs>
    <a-table
      bordered
      :row-key="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      :pagination="{
        total: total,
        pageSize: search.size,
        current: search.current,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total}条`,
      }"
      :scroll="{ x: 'max-content', y: tableX }"
      @change="tablePaginationChange"
    ></a-table>
    <!-- <template v-if="type == '1' && options">
      <div class="charts-box">
        <div ref="bar" class="charts"></div>
      </div>
    </template>
    <no-data v-if="type == '1' && !options" message="暂无数据"></no-data> -->
  </div>
</template>

<script>
import subjectList from "@/views/union/components/subjectList.vue";
import { getstatschooloption } from "@/core/api/academic/common";
import { mapState } from "vuex";
import { gradescorelist } from "@/core/api/academic/unionSheet";
import { gradescorelistExport } from "@/core/api/academic/unionSheetExport";
export default {
  name: "",
  components: {
    subjectList,
  },
  data() {
    return {
      tableData: [],
      type: "0",
      dataType: 1,
      subjectId: null,
      schoolId: null,
      columns: [],
      schoolList: [],
      loading: false,
      options: null,
      subjectName: "",
      btnLoading: false,
      tableX: window.innerHeight - 330,
      subjectList: [],
      total: 0,
      apCols: [
        {
          title: "整体排名",
          dataIndex: "unionRank",
          width: 75,
          align: "center",
        },
        {
          dataIndex: "eleccateUnionRank",
          title: "整体选考类别排名",
          width: 135,
          align: "center",
        },
        {
          dataIndex: "eleccombUnionRank",
          title: "整体选考组合排名",
          width: 135,
          align: "center",
        },
        {
          title: "校内排名",
          dataIndex: "schoolRank",
          width: 75,
          align: "center",
        },
        {
          dataIndex: "eleccateSchoolRank",
          title: "校内选考类别排名",
          width: 135,
          align: "center",
        },
        {
          dataIndex: "eleccombSchoolRank",
          title: "校内选考组合排名",
          width: 135,
          align: "center",
        },
      ],
      cols: [
        {
          title: "整体排名",
          dataIndex: "unionRank",
          width: 75,
          align: "center",
        },
        {
          title: "校内排名",
          dataIndex: "schoolRank",
          width: 75,
          align: "center",
        },
      ],
      search: {
        size: 20,
        current: 1,
      },
    };
  },
  computed: {
    ...mapState({
      scoreType: (state) => state.report.scoreType,
    }),
  },
  mounted() {
    // this.getData();
  },
  methods: {
    scoreClick(subjectId, record, answerSheetUrl) {
      if (answerSheetUrl) {
        sessionStorage.setItem("imgList", answerSheetUrl);
        const { href } = this.$router.resolve({
          path: "/academic/card",
          query: {
            name: record.name,
            studentId: record.studentId,
            statId: this.$route.query.id,
            subjectId: subjectId,
          },
        });
        window.open(href, "_blank");
      } else {
        this.$message.error("无可查看答题卡");
      }
    },
    async downloadFile() {
      let res;
      try {
        this.btnLoading = true;
        res = await gradescorelistExport({
          statId: this.$route.query.id,
          subjectId: this.subjectId,
          isDetails: this.type,
          schoolId: this.schoolId,
          ...this.search,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
        });
        const { name, url } = res.data.data;
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: url,
            name: name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.btnLoading = false;
      } catch {
        this.btnLoading = false;
      }
    },
    tablePaginationChange(val) {
      if (this.search.size !== val.pageSize) {
        this.search.current = 1;
        this.search.size = val.pageSize;
      } else {
        this.search.current = val.current;
      }
      this.getData();
    },

    async getSchoolList() {
      const res = await getstatschooloption({ id: this.$route.query.id });
      this.schoolList = res.data.data;
      if (res.data.data.length) {
        this.schoolId = res.data.data[0].id;
        this.tabsChange();
      }
    },
    handleChange() {
      this.tabsChange();
    },
    dataTypeChange() {
      this.tabsChange();
    },
    async getData() {
      try {
        this.loading = true;
        const res = await gradescorelist({
          statId: this.$route.query.id,
          subjectId: this.subjectId,
          isDetails: this.type,
          schoolId: this.schoolId,
          ...this.search,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
        });
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        if (this.type == 0) {
          if (this.dataType == 2) {
            this.setCol();
          } else {
            this.setOriCol();
          }
        } else {
          if (this.dataType == 2) {
            this.setDetailCol();
          } else {
            this.setDetailOriCol();
          }
        }

        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    setCol() {
      let cols = [];
      if (this.tableData.length) {
        if (this.subjectId == 0) {
          let subjects = this.tableData[0].subjects || [];
          cols = [
            {
              title: "总分",
              dataIndex: "totalScore",
              align: "center",
              children: [
                {
                  title: "原始分",
                  dataIndex: "totalScoreOri",
                  width: 60,
                  align: "center",
                  customRender: (text, record) => record.scoreOri,
                },
                {
                  title: "赋分",
                  width: 60,
                  dataIndex: "totalScore",
                  align: "center",
                  customRender: (text, record) => record.score,
                },
              ],
            },
            ...subjects.map((item, index) => {
              if ([1, 2, 3, 4, 8].includes(item.subjectId)) {
                return {
                  title: item.subjectName,
                  dataIndex: "subject" + index,
                  width: 100,
                  align: "center",
                  customRender: (text, record) => {
                    let subject = record.subjects.find(
                      (it) => it.subjectId === item.subjectId
                    );
                    if (item.answerSheetUrl) {
                      return (
                        <span
                          vOn:click={() =>
                            this.scoreClick(
                              subject.subjectId,
                              record,
                              subject.answerSheetUrl
                            )
                          }
                          class="pointScore"
                        >
                          {subject.scoreOri}
                        </span>
                      );
                    } else {
                      return subject.scoreOri;
                    }
                  },
                };
              } else {
                return {
                  title: item.subjectName,
                  dataIndex: "subject" + index,
                  align: "center",
                  children: [
                    {
                      title: "原始分",
                      dataIndex: "scoreOri" + index,
                      width: 60,
                      align: "center",
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        if (item.answerSheetUrl) {
                          return (
                            <span
                              vOn:click={() =>
                                this.scoreClick(
                                  subject.subjectId,
                                  record,
                                  subject.answerSheetUrl
                                )
                              }
                              class="pointScore"
                            >
                              {subject.scoreOri}
                            </span>
                          );
                        } else {
                          return subject.scoreOri;
                        }
                      },
                    },
                    {
                      title: "赋分",
                      width: 60,
                      dataIndex: "score" + index,
                      align: "center",
                      customRender: (text, record) =>
                        record.subjects[index].score,
                    },
                  ],
                };
              }
            }),
          ];
        } else {
          cols = [
            {
              title: "原始分",
              dataIndex: "scoreOri",
              width: 65,
              align: "center",
              customRender: (text, record) => {
                if (record.answerSheetUrl) {
                  return (
                    <span
                      vOn:click={() =>
                        this.scoreClick(
                          record.subjectId,
                          record,
                          record.answerSheetUrl
                        )
                      }
                      class="pointScore"
                    >
                      {record.scoreOri}
                    </span>
                  );
                } else {
                  return record.scoreOri;
                }
              },
            },
            {
              title: "赋分",
              dataIndex: "score",
              width: 65,
              align: "center",
            },
            {
              title: "客观题",
              dataIndex: "objectiveScore",
              width: 65,
              align: "center",
            },
            {
              title: "主观题",
              dataIndex: "subjectiveScore",
              width: 65,
              align: "center",
            },
          ];
        }
      }
      this.columns = [
        {
          title: "整体排名",
          dataIndex: "unionRank",
          width: 75,
          align: "center",
        },
        {
          dataIndex: "eleccateUnionRank",
          title: "整体选考类别排名",
          width: 135,
          align: "center",
        },
        {
          dataIndex: "eleccombUnionRank",
          title: "整体选考组合排名",
          width: 135,
          align: "center",
        },
        {
          title: "校内排名",
          dataIndex: "schoolRank",
          width: 75,
          align: "center",
        },
        {
          dataIndex: "eleccateSchoolRank",
          title: "校内选考类别排名",
          width: 135,
          align: "center",
        },
        {
          dataIndex: "eleccombSchoolRank",
          title: "校内选考组合排名",
          width: 135,
          align: "center",
        },
        {
          title: "姓名",
          dataIndex: "name",
          width: 90,
          align: "center",
        },
        {
          dataIndex: "studentNo",
          title: "学籍号",
          width: 110,
          align: "center",
        },
        {
          title: "考号",
          dataIndex: "studentExamId",
          width: 120,
          align: "center",
        },
        {
          title: "行政班级",
          width: 75,
          dataIndex: "classNum",
          align: "center",
        },
        {
          title: "选考组合",
          width: 75,
          dataIndex: "eleccomb",
          align: "center",
        },
        ...cols,
      ];
      if (this.$route.query.scoreType == 0) {
        this.columns = [
          {
            title: "整体排名",
            dataIndex: "unionRank",
            width: 75,
            align: "center",
          },
          {
            dataIndex: "eleccateUnionRank",
            title: "整体选考类别排名",
            width: 135,
            align: "center",
          },
          {
            dataIndex: "eleccombUnionRank",
            title: "整体选考组合排名",
            width: 135,
            align: "center",
          },
          {
            title: "校内排名",
            dataIndex: "schoolRank",
            width: 75,
            align: "center",
          },
          {
            dataIndex: "eleccateSchoolRank",
            title: "校内选考类别排名",
            width: 135,
            align: "center",
          },
          {
            dataIndex: "eleccombSchoolRank",
            title: "校内选考组合排名",
            width: 135,
            align: "center",
          },
          {
            title: "姓名",
            dataIndex: "name",
            width: 90,
            align: "center",
          },
          {
            dataIndex: "studentNo",
            title: "学籍号",
            width: 110,
            align: "center",
          },
          {
            title: "考号",
            dataIndex: "studentExamId",
            width: 120,
            align: "center",
          },
          {
            title: "行政班级",
            width: 75,
            dataIndex: "classNum",
            align: "center",
          },
          {
            title: "选考组合",
            width: 75,
            dataIndex: "eleccomb",
            align: "center",
          },
          ...cols,
          {
            dataIndex: "studentType",
            title: "分类",
            align: "center",
            width: 85,
          },
          {
            dataIndex: "categoryUnionRank",
            title: "整体分类排名",
            align: "center",
            width: 110,
          },
          {
            dataIndex: "categorySchoolRank",
            title: "校内分类排名",
            align: "center",
            width: 110,
          },
        ];
      }
    },
    setOriCol() {
      let col = [];
      let headCol = [];
      if (this.tableData.length) {
        if (this.$route.query.ruleId > 0 && this.$route.query.scoreType == 1) {
          headCol = this.apCols;
          if (this.subjectId == 0) {
            let subjects = this.tableData[0].subjects || [];
            col = [
              {
                title: "总分",
                dataIndex: "scoreOri",
                width: 60,
                align: "center",
              },
              ...subjects.map((item, index) => ({
                title: item.subjectName,
                dataIndex: "subject" + index,
                align: "center",
                width: 60,
                customRender: (text, record) => {
                  let subject = record.subjects.find(
                    (it) => it.subjectId === item.subjectId
                  );
                  if (item.answerSheetUrl) {
                    return (
                      <span
                        vOn:click={() =>
                          this.scoreClick(
                            subject.subjectId,
                            record,
                            subject.answerSheetUrl
                          )
                        }
                        class="pointScore"
                      >
                        {subject.scoreOri}
                      </span>
                    );
                  } else {
                    return subject.scoreOri;
                  }
                },
              })),
            ];
          } else {
            col = [
              {
                title: "得分",
                dataIndex: "scoreOri",
                width: 65,
                align: "center",
                customRender: (text, record) => {
                  if (record.answerSheetUrl) {
                    return (
                      <span
                        vOn:click={() =>
                          this.scoreClick(
                            record.subjectId,
                            record,
                            record.answerSheetUrl
                          )
                        }
                        class="pointScore"
                      >
                        {record.scoreOri}
                      </span>
                    );
                  } else {
                    return record.scoreOri;
                  }
                },
              },
              {
                title: "客观题",
                dataIndex: "objectiveScore",
                width: 65,
                align: "center",
              },
              {
                title: "主观题",
                dataIndex: "subjectiveScore",
                width: 65,
                align: "center",
              },
            ];
          }
        } else {
          headCol = this.cols;
          if (this.subjectId == 0) {
            let subjects = this.tableData[0].subjects || [];
            col = [
              {
                title: "总分",
                dataIndex: "scoreOri",
                align: "center",
                width: 60,
                customRender: (text, record) => record.scoreOri,
              },
              ...subjects.map((item, index) => {
                if ([1, 2, 3, 4, 8].includes(item.subjectId)) {
                  return {
                    title: item.subjectName,
                    dataIndex: "subject" + index,
                    width: 60,
                    align: "center",
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      if (item.answerSheetUrl) {
                        return (
                          <span
                            vOn:click={() =>
                              this.scoreClick(
                                subject.subjectId,
                                record,
                                subject.answerSheetUrl
                              )
                            }
                            class="pointScore"
                          >
                            {subject.scoreOri}
                          </span>
                        );
                      } else {
                        return subject.scoreOri;
                      }
                    },
                  };
                } else {
                  return {
                    title: item.subjectName,
                    width: 60,
                    dataIndex: "subject" + index,
                    align: "center",
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      if (item.answerSheetUrl) {
                        return (
                          <span
                            vOn:click={() =>
                              this.scoreClick(
                                subject.subjectId,
                                record,
                                subject.answerSheetUrl
                              )
                            }
                            class="pointScore"
                          >
                            {subject.scoreOri}
                          </span>
                        );
                      } else {
                        return subject.scoreOri;
                      }
                    },
                  };
                }
              }),
            ];
          } else {
            col = [
              {
                title: "得分",
                dataIndex: "scoreOri",
                width: 60,
                align: "center",
                customRender: (text, record) => {
                  if (record.answerSheetUrl) {
                    return (
                      <span
                        vOn:click={() =>
                          this.scoreClick(
                            record.subjectId,
                            record,
                            record.answerSheetUrl
                          )
                        }
                        class="pointScore"
                      >
                        {record.scoreOri}
                      </span>
                    );
                  } else {
                    return record.scoreOri;
                  }
                },
              },
              {
                title: "客观题",
                dataIndex: "objectiveScore",
                width: 60,
                align: "center",
              },
              {
                title: "主观题",
                dataIndex: "subjectiveScore",
                width: 60,
                align: "center",
              },
            ];
          }
        }
      }
      this.columns = [
        ...headCol,

        {
          title: "姓名",
          dataIndex: "name",
          width: 90,
          align: "center",
        },
        {
          dataIndex: "studentNo",
          title: "学籍号",
          width: 110,
          align: "center",
        },
        {
          title: "考号",
          dataIndex: "studentExamId",
          width: 120,
          align: "center",
        },
        {
          title: "选考组合",
          dataIndex: "eleccomb",
          width: 75,
          align: "center",
        },
        {
          title: [0, 1, 2, 3].includes(this.subjectId)
            ? "行政班级"
            : "教学班级",
          dataIndex: "classNum",
          width: 75,
          align: "center",
        },
        ...col,
      ];
      if (this.$route.query.scoreType == 0) {
        this.columns = [
          ...headCol,

          {
            title: "姓名",
            dataIndex: "name",
            width: 90,
            align: "center",
          },
          {
            dataIndex: "studentNo",
            title: "学籍号",
            width: 110,
            align: "center",
          },
          {
            title: "考号",
            dataIndex: "studentExamId",
            width: 120,
            align: "center",
          },
          {
            title: [0, 1, 2, 3].includes(this.subjectId)
              ? "行政班级"
              : "教学班级",
            dataIndex: "classNum",
            width: 75,
            align: "center",
          },
          ...col,
          {
            dataIndex: "studentType",
            title: "分类",
            align: "center",
            width: 85,
          },
          {
            dataIndex: "categoryUnionRank",
            title: "整体分类排名",
            align: "center",
            width: 110,
          },
          {
            dataIndex: "categorySchoolRank",
            title: "校内分类排名",
            align: "center",
            width: 110,
          },
        ];
      }
    },
    setDetailCol() {
      let cols = [];
      if (this.tableData.length) {
        if (this.subjectId == 0) {
          let subjects = this.tableData[0].subjects || [];
          cols = [
            {
              title: "总分",
              dataIndex: "subjectTotal",
              align: "center",
              children: [
                {
                  title: "原始分",
                  dataIndex: "scoreOriTotal",
                  width: 60,
                  align: "center",
                  customRender: (text, record) => record.scoreOri,
                },
                {
                  title: "赋分",
                  dataIndex: "scoreTotal",
                  width: 60,
                  align: "center",
                  customRender: (text, record) => record.score,
                },
              ],
            },
            ...subjects.map((item, index) => {
              if ([1, 2, 3, 4, 8].includes(item.subjectId)) {
                return {
                  title: item.subjectName,
                  dataIndex: "subject" + index,
                  align: "center",
                  children: [
                    {
                      title: "原始分",
                      align: "center",
                      dataIndex: "scoreOri" + index,
                      width: 60,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        if (item.answerSheetUrl) {
                          return (
                            <span
                              vOn:click={() =>
                                this.scoreClick(
                                  subject.subjectId,
                                  record,
                                  subject.answerSheetUrl
                                )
                              }
                              class="pointScore"
                            >
                              {subject.scoreOri}
                            </span>
                          );
                        } else {
                          return subject.scoreOri;
                        }
                      },
                    },
                    {
                      title: "校内排名",
                      align: "center",
                      dataIndex: "schoolRank" + index,
                      width: 75,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.schoolRank;
                      },
                    },
                    {
                      title: "校内选考类别排名",
                      align: "center",
                      dataIndex: "eleccateSchoolRank" + index,
                      width: 135,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.eleccateSchoolRank;
                      },
                    },
                    {
                      title: "校内选考组合排名",
                      align: "center",
                      dataIndex: "eleccombSchoolRank" + index,
                      width: 135,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.eleccombSchoolRank;
                      },
                    },
                    {
                      title: "整体排名",
                      align: "center",
                      dataIndex: "unionRank" + index,
                      width: 75,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.unionRank;
                      },
                    },
                    {
                      title: "整体选考类别排名",
                      align: "center",
                      dataIndex: "eleccateUnionRank" + index + item.subjectId,
                      width: 135,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.eleccateUnionRank;
                      },
                    },
                    {
                      title: "整体选考组合排名",
                      align: "center",
                      dataIndex: "eleccombUnionRank" + index,
                      width: 135,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.eleccombUnionRank;
                      },
                    },
                  ],
                };
              } else {
                return {
                  title: item.subjectName,
                  dataIndex: "subject" + index,
                  align: "center",
                  children: [
                    {
                      title: "原始分",
                      align: "center",
                      dataIndex: "scoreOri" + index,
                      width: 60,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.scoreOri;
                      },
                    },
                    {
                      title: "赋分",
                      align: "center",
                      dataIndex: "score" + index,
                      width: 60,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.score;
                      },
                    },
                    {
                      title: "等级",
                      align: "center",
                      dataIndex: "level" + index,
                      width: 45,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.level;
                      },
                    },
                    {
                      title: "校内选考类别排名",
                      align: "center",
                      dataIndex: "eleccateSchoolRank" + index,
                      width: 135,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.eleccateSchoolRank;
                      },
                    },
                    {
                      title: "校内选考组合排名",
                      align: "center",
                      dataIndex: "eleccombSchoolRank" + index,
                      width: 135,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.eleccombSchoolRank;
                      },
                    },
                    {
                      title: "校内排名",
                      align: "center",
                      dataIndex: "schoolRank" + index,
                      width: 75,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.schoolRank;
                      },
                    },
                    {
                      title: "整体选考类别排名",
                      align: "center",
                      dataIndex: item.subjectId + "eleccateUnionRank" + index,
                      width: 135,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.eleccateUnionRank;
                      },
                    },
                    {
                      title: "整体选考组合排名",
                      align: "center",
                      dataIndex: "eleccombUnionRank" + index,
                      width: 135,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.eleccombUnionRank;
                      },
                    },
                    {
                      title: "整体排名",
                      align: "center",
                      dataIndex: "unionRank" + index,
                      width: 75,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.unionRank;
                      },
                    },
                  ],
                };
              }
            }),
          ];
        } else {
          let questions = this.tableData[0].questions;
          cols = [
            {
              title: "原始分",
              dataIndex: "scoreOri",
              width: 65,
              align: "center",
              customRender: (text, record) => {
                if (record.answerSheetUrl) {
                  return (
                    <span
                      vOn:click={() =>
                        this.scoreClick(
                          record.subjectId,
                          record,
                          record.answerSheetUrl
                        )
                      }
                      class="pointScore"
                    >
                      {record.scoreOri}
                    </span>
                  );
                } else {
                  return record.scoreOri;
                }
              },
            },
            {
              title: "赋分",
              dataIndex: "score",
              width: 65,
              align: "center",
            },
            {
              title: "客观题",
              dataIndex: "objectiveScore",
              width: 65,
              align: "center",
            },
            {
              title: "主观题",
              dataIndex: "subjectiveScore",
              width: 65,
              align: "center",
            },
            ...questions.map((item, index) => {
              return {
                title: item.order,
                width: 65,
                dataIndex: "order" + index,
                align: "center",
                customRender: (text, record) => record.questions[index].score,
              };
            }),
            {
              title: "备注",
              dataIndex: "comment",
              width: 65,
              align: "center",
            },
          ];
        }
      }
      this.columns = [
        {
          title: "整体排名",
          dataIndex: "unionRank",
          width: 75,
          align: "center",
        },
        {
          dataIndex: "eleccateUnionRank",
          title: "整体选考类别排名",
          width: 135,
          align: "center",
        },
        {
          dataIndex: "eleccombUnionRank",
          title: "整体选考组合排名",
          width: 135,
          align: "center",
        },
        {
          title: "校内排名",
          dataIndex: "schoolRank",
          width: 75,
          align: "center",
        },
        {
          dataIndex: "eleccateSchoolRank",
          title: "校内选考类别排名",
          width: 135,
          align: "center",
        },
        {
          dataIndex: "eleccombSchoolRank",
          title: "校内选考组合排名",
          width: 135,
          align: "center",
        },
        {
          title: "姓名",
          dataIndex: "name",
          width: 90,
          align: "center",
        },
        {
          dataIndex: "studentNo",
          title: "学籍号",
          width: 110,
          align: "center",
        },
        {
          title: "考号",
          width: 120,
          dataIndex: "studentExamId",
          align: "center",
        },
        {
          title: "行政班级",
          dataIndex: "classNum",
          width: 75,
          align: "center",
        },
        {
          title: "选考组合",
          dataIndex: "eleccomb",
          width: 75,
          align: "center",
        },
        ...cols,
      ];
      if (this.$route.query.scoreType == 0) {
        this.columns = [
          {
            title: "整体排名",
            dataIndex: "unionRank",
            width: 75,
            align: "center",
          },
          {
            dataIndex: "eleccateUnionRank",
            title: "整体选考类别排名",
            width: 135,
            align: "center",
          },
          {
            dataIndex: "eleccombUnionRank",
            title: "整体选考组合排名",
            width: 135,
            align: "center",
          },
          {
            title: "校内排名",
            dataIndex: "schoolRank",
            width: 75,
            align: "center",
          },
          {
            dataIndex: "eleccateSchoolRank",
            title: "校内选考类别排名",
            width: 135,
            align: "center",
          },
          {
            dataIndex: "eleccombSchoolRank",
            title: "校内选考组合排名",
            width: 135,
            align: "center",
          },
          {
            title: "姓名",
            dataIndex: "name",
            width: 90,
            align: "center",
          },
          {
            dataIndex: "studentNo",
            title: "学籍号",
            width: 110,
            align: "center",
          },
          {
            title: "考号",
            width: 120,
            dataIndex: "studentExamId",
            align: "center",
          },
          {
            title: "行政班级",
            dataIndex: "classNum",
            width: 75,
            align: "center",
          },
          {
            title: "选考组合",
            dataIndex: "eleccomb",
            width: 75,
            align: "center",
          },
          ...cols,
          {
            dataIndex: "studentType",
            title: "分类",
            align: "center",
            width: 85,
          },
          {
            dataIndex: "categoryUnionRank",
            title: "整体分类排名",
            align: "center",
            width: 110,
          },
          {
            dataIndex: "categorySchoolRank",
            title: "校内分类排名",
            align: "center",
            width: 110,
          },
        ];
      }
    },
    setDetailOriCol() {
      let cols = [];
      let detailCol = [];
      let isAp = this.$route.query.ruleId > 0 && this.$route.query.scoreType;
      if (this.tableData.length) {
        if (this.subjectId == 0) {
          let subjects = this.tableData[0].subjects || [];
          // let eleccate = isAp
          //   ? [
          //       {
          //         title: "选考组合",
          //         width: 75,
          //         dataIndex: "eleccomb",
          //         align: "center",
          //       },
          //     ]
          //   : [];
          cols = [
            // ...eleccate,
            {
              title: "总分",
              dataIndex: "scoreOri",
              align: "center",
              width: 60,
              customRender: (text, record) => record.scoreOri,
            },

            ...subjects.map((item, index) => {
              if ([0].includes(item.subjectId)) {
                return {
                  title: item.subjectName,
                  dataIndex: "subject" + index,
                  align: "center",
                  width: 60,
                  children: [
                    {
                      title: "得分",
                      dataIndex: "scoreOri" + index,
                      width: 60,
                      align: "center",
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        if (item.answerSheetUrl) {
                          return (
                            <span
                              vOn:click={() =>
                                this.scoreClick(
                                  subject.subjectId,
                                  record,
                                  subject.answerSheetUrl
                                )
                              }
                              class="pointScore"
                            >
                              {subject.scoreOri}
                            </span>
                          );
                        } else {
                          return subject.scoreOri;
                        }
                      },
                    },
                  ],
                };
              } else {
                let isApCol = [
                  {
                    title: "校内排名",
                    align: "center",
                    dataIndex: "schoolRank" + index,
                    width: 75,
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.schoolRank;
                    },
                  },
                  {
                    title: "校内选考类别排名",
                    align: "center",
                    dataIndex: "eleccateSchoolRank" + index,
                    width: 135,
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.eleccateSchoolRank;
                    },
                  },
                  {
                    title: "校内选考组合排名",
                    align: "center",
                    dataIndex: "eleccombSchoolRank" + index,
                    width: 135,
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.eleccombSchoolRank;
                    },
                  },
                  {
                    title: "整体排名",
                    align: "center",
                    dataIndex: "unionRank" + index,
                    width: 75,
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.unionRank;
                    },
                  },
                  {
                    title: "整体选考类别排名",
                    align: "center",
                    dataIndex: "eleccateUnionRank" + index + item.subjectId,
                    width: 135,
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.eleccateUnionRank;
                    },
                  },
                  {
                    title: "整体选考组合排名",
                    align: "center",
                    dataIndex: "eleccombUnionRank" + index,
                    width: 135,
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.eleccombUnionRank;
                    },
                  },
                ];
                let notApCol = [
                  {
                    title: "校内排名",
                    align: "center",
                    dataIndex: "schoolRank" + index,
                    width: 75,
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.schoolRank;
                    },
                  },

                  {
                    title: "整体排名",
                    align: "center",
                    dataIndex: "unionRank" + index,
                    width: 75,
                    customRender: (text, record) => {
                      let subject = record.subjects.find(
                        (it) => it.subjectId === item.subjectId
                      );
                      return subject.unionRank;
                    },
                  },
                ];
                let rankCols = isAp ? isApCol : notApCol;
                return {
                  title: item.subjectName,
                  dataIndex: "subject" + index,
                  align: "center",
                  width: 60,
                  children: [
                    {
                      title: "得分",
                      align: "center",
                      dataIndex: "scoreOri" + index,
                      width: 60,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        if (item.answerSheetUrl) {
                          return (
                            <span
                              vOn:click={() =>
                                this.scoreClick(
                                  subject.subjectId,
                                  record,
                                  subject.answerSheetUrl
                                )
                              }
                              class="pointScore"
                            >
                              {subject.scoreOri}
                            </span>
                          );
                        } else {
                          return subject.scoreOri;
                        }
                      },
                    },

                    ...rankCols,
                  ],
                };
              }
            }),
          ];
        } else {
          let questions = this.tableData[0].questions || [];
          // let arr = isAp
          //   ? [
          //       {
          //         title: "选考组合",
          //         dataIndex: "eleccomb",
          //         width: 75,
          //         align: "center",
          //       },
          //     ]
          //   : [];
          cols = [
            // ...arr,
            {
              title: "得分",
              dataIndex: "scoreOri",
              width: 60,
              align: "center",
              customRender: (text, record) => {
                if (record.answerSheetUrl) {
                  return (
                    <span
                      vOn:click={() =>
                        this.scoreClick(
                          record.subjectId,
                          record,
                          record.answerSheetUrl
                        )
                      }
                      class="pointScore"
                    >
                      {record.scoreOri}
                    </span>
                  );
                } else {
                  return record.scoreOri;
                }
              },
            },
            {
              title: "客观题",
              dataIndex: "objectiveScore",
              width: 60,
              align: "center",
            },
            {
              title: "主观题",
              dataIndex: "subjectiveScore",
              width: 60,
              align: "center",
            },
            ...questions.map((item, index) => ({
              title: item.order,
              dataIndex: "order" + index,
              width: 50,
              align: "center",
              customRender: (text, record) => record.questions[index].score,
            })),
          ];
        }
      }
      if (this.$route.query.ruleId > 0 && this.$route.query.scoreType == 1) {
        detailCol = this.apCols;
      } else {
        detailCol = [
          {
            title: "整体排名",
            dataIndex: "unionRank",
            width: 75,
            align: "center",
          },
          {
            title: "校内排名",
            dataIndex: "schoolRank",
            width: 75,
            align: "center",
          },
        ];
      }
      this.columns = [
        ...detailCol,
        {
          title: "姓名",
          dataIndex: "name",
          width: 90,
          align: "center",
        },
        {
          dataIndex: "studentNo",
          title: "学籍号",
          width: 110,
          align: "center",
        },
        {
          title: "考号",
          dataIndex: "studentExamId",
          width: 120,
          align: "center",
        },
        {
          title: "行政班级",
          dataIndex: "classNum",
          width: 75,
          align: "center",
        },
        {
          dataIndex: "eleccomb",
          title: "选考组合",
          width: 75,
          align: "center",
        },
        ...cols,
      ];
      if (this.$route.query.scoreType == 0) {
        this.columns = [
          ...detailCol,
          {
            title: "姓名",
            dataIndex: "name",
            width: 90,
            align: "center",
          },
          {
            dataIndex: "studentNo",
            title: "学籍号",
            width: 110,
            align: "center",
          },
          {
            title: "考号",
            dataIndex: "studentExamId",
            width: 120,
            align: "center",
          },
          {
            title: "行政班级",
            dataIndex: "classNum",
            width: 75,
            align: "center",
          },
          ...cols,
          {
            dataIndex: "studentType",
            title: "分类",
            align: "center",
            width: 85,
          },
          {
            dataIndex: "categoryUnionRank",
            title: "整体分类排名",
            align: "center",
            width: 110,
          },
          {
            dataIndex: "categorySchoolRank",
            title: "校内分类排名",
            align: "center",
            width: 110,
          },
        ];
      }
    },
    subjectChange() {
      if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.dataType = 1;
      }
      if (this.subjectId == 0) {
        this.subjectName = "总分";
      } else {
        let subject = this.$refs.subject.subjectList.find(
          (item) => item.id == this.subjectId
        );
        this.subjectName = subject.label;
      }
      this.getSchoolList();
    },
    tabsChange() {
      if (this.subjectId !== null) {
        if (this.schoolId) {
          this.getData();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .title-content {
      display: flex;
      align-items: center;
      .title {
        font-size: 14px;
        color: #0a1119;
        font-weight: 600;
        margin-right: 8px;
      }
    }
  }
}
::v-deep .ant-tabs-bar {
  margin: 0 0 0 0 !important;
}
.filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .filter-item {
    margin-bottom: 18px;
    margin-right: 18px;
  }
}
.charts-box {
  width: 100%;
  .charts {
    width: 100%;
    height: 650px;
  }
}
::v-deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px 6px !important;
}
::v-deep .ant-table-row-cell-break-word {
  padding: 4px 6px !important;
}
::v-deep .ant-table-pagination.ant-pagination {
  float: right;
  margin: 10px 0;
}
.pointScore {
  cursor: pointer;
  color: #2474ed;
}
</style>
